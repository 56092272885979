import Vue from 'vue'
import VueRouter from 'vue-router'
import Homeconter from '../views/HomeView.vue';
import Login from '../views/Login.vue'

Vue.use(VueRouter)
const routes = [
 
  {
    path: '/Homeconter',
    name: 'Homeconter',
    // component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue'), 
    component: Homeconter,
  },
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/ChangePassword',
    name: 'ChangePassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/ChangePassword.vue'),    
  },
  {
    path: '/ProjectReporting',
    name: 'ProjectReporting',
    component: () => import(/* webpackChunkName: "about" */ '../views/page/xiangmubaobei.vue'),    
  },
  {
    path: '/ReportingRecords',
    name: 'ReportingRecords',
    component: () => import(/* webpackChunkName: "about" */ '../views/page/baobeijilu.vue'),    
  },
  {
    path: '/AllocationRecords',
    name: 'AllocationRecords',
    component: () => import(/* webpackChunkName: "about" */ '../views/page/fenbeijilu.vue'),    
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 在 Vue 路由配置中使用 router.beforeEach 注册全局前置守卫

router.beforeEach((to, from, next) => {
    // 检查本地存储或 Vuex store 中是否存在有效的登录凭证
    let  isLoggedIn = localStorage.getItem('cookie');
       if (isLoggedIn==null) {
          isLoggedIn = true
       }
    if (!isLoggedIn) {
      // 如果未登录，则重定向到登录页面
      next({ name: 'Login' });
    } else {
      // 如果已登录，则正常导航
      next();
    }
});
export default router
