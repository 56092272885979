//引入
import axios from "./axios.js"; //路劲自己修改
// 登录
export const LoginAdd = (params) => {
    return axios({
        url: "/api/home/login/login",
        method: "post" ,  
        data: params,

    })
}
// 退出登录
export const LoginOut = (params) => {
    return axios({
        url: "/api/home/login/login_out",
        method: "post" ,  
        data: params
    })
}
// 修改密码
export const updatePassword = (params) => {
    return axios({
        url: "/api/home/login/update_password",
        method: "post" ,  
        data: params
    })
}
// 报备
export const jobSub = (params) => {
    return axios({
        url: "/api/home/pages/job_sub",
        method: "post" ,  
        data: params
    })
}        
 // 合伙人报备记录
 export const pagesIndex = (params) => {
    return axios({
        url: "/api/home/pages/index",
        method: "post" ,  
        data: params
    })
} 
// 业务员负责任务
export const jobList = (params) => {
    return axios({
        url: "/api/home/pages/job_list",
        method: "post" ,  
        data: params
    })
} 
// 跟进记录(业务员的跟进记录)
export const logList = (params) => {
    return axios({
        url: "/api/home/pages/log_list",
        method: "post" ,  
        data: params
    })
}
// 报备详情
export const reportdetail = (params) => {
    return axios({
        url: "/api/home/pages/report_detail",
        method: "post" ,  
        data: params
    })
}
// 添加跟进记录
export const jobAdd = (params) => {
    return axios({
        url: "/api/home/pages/job_add",
        method: "post" ,  
        data: params
    })
}
