<template>
  <div class="Login">
    <div class="login_center">
      <div class="Welcome">
        <div>欢迎登录</div>
        <div class="Welcome_line"></div>
      </div>
      <van-form @submit="onSubmit">
        <div class="mar">
          <div class="mrimg">
            <img src="../../src/assets/59.png" alt class="marimgicon" />
          </div>
          <van-field
            v-model="form.telephone"
            name=" "
            label=" "
            placeholder="手机号"
            label-width="2em"
            type="digit"
            class="vancll"
          ></van-field>
        </div>

        <div class="mar">
          <div class="mrimg">
            <img src="../../src/assets/37.png" alt class="marimgicon" />
          </div>
          <van-field
          class="vancll"
            v-model="form.password"
            type="password"
            name=" "
            label=" "
            placeholder="密码"
            label-width="2em"
          />
        </div>
        <div style="margin: 10px;" >
          <van-button block native-type="submit"  text="28279A" round class="vanbtn"> 登 录</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
import { LoginAdd } from "../api/home.js";
export default {
  data() {
    return {
      form: {
        telephone: "",
        password: "",
        yzmvalue: ""
      },
      loginUserNameIcon: "/pages/img/username.png",
      passwordIcon: "../../src/assets/37.png"
    };
  },
  created(){
    const token = localStorage.getItem("Token")
    if(token){
      this.$router.push("/Homeconter");
    }
  }
  ,
  methods: {
    // 修改密码
    ChangePassword() {
      this.$router.push("/ChangePassword");
    },
    // 登陆
    onSubmit() {
      let val = /^1[3456789]\d{9}$/.test(this.form.telephone);
      if (!val || this.form.telephone == "") {
        this.$toast("请输入正确的手机号");
        return;
      }
      if (this.form.password == "") {
        this.$toast("请输入密码");
        return;
      }
      let promes = {
        phone: this.form.telephone,
        password: this.form.password
      };
      LoginAdd(promes).then(res => {
        console.log(12465);
        console.log(res);
        if (res.code === 200) {
          localStorage.setItem('Token', res.data.token);
          localStorage.setItem('userinfo', JSON.stringify(res.data));
          this.$router.push("/Homeconter");
        } else if (res.data === 401) {
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
.xgmm {
  display: flex;
  color: #ffffff;
  margin-right: 10px;
  padding: 5px;
  border-radius: 2px;
  margin-bottom: 5px;
  background: #999999;
}
.xgmmimg {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.Login {
  width: 100vw;
  height: 100vh;
  background-image: url("../../src/assets/16.jpg"); /* 设置图片路径 */
  background-size: cover; /* 背景图片覆盖整个元素 */
  background-repeat: no-repeat; /* 背景图片不重复 */
  background-position: center; /* 背景图片居中 */
  position: relative;
}

.login_center {
  width: 400px;
  height: 350px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(42, 38, 38, 0.23);
}
.mar {
  margin: 23px 10px;
  position: relative;
}
.mrimg {
  position: absolute;
  top: 10px;
  left: 12px;
  width: 25px;
  height: 24px;
  z-index: 3;
}
.marimgicon {
  width: 100%;
  height: 100%;
}

::v-deep van-button__text {
  color: red;
}
@media (max-width: 750px) {
  .login_center {
    width: 75%;
    height: 300px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
  }
}
.vanbtn{
  color: rgba(255, 255, 255, 1);
  border: none;
  background: linear-gradient(270deg, rgba(200, 224, 251, 1) 0%, rgba(58, 113, 190, 1) 100%);
}
.vancll{
  border-radius: 5px;
 border: 1px solid rgba(153, 153, 153, 1);
}
</style>