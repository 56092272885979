<template>
  <!-- userinfo.role==1业务员 2合伙人-->
  <div class="Parent_element">
    <!-- @click="Launchlogin" -->
    <div class="Launch">
      <van-popover
        v-model="showPopover"
        trigger="click"
        :actions="actions"
        @select="onSelect"
        placement="bottom-start"
      >
        <template #reference>
          <div class="reference">
            <img src="../../src/assets/54.png" alt class="Launch_img" />
            <div class="title">
              <div>Hi</div>
              <div>{{ userinfo.title}}</div>
            </div>
          </div>
        </template>
      </van-popover>
    </div>
    <div class="entrance fxcolumn">
      <div @click="entrance" class="entrancediv mb fx" v-if="userinfo.role==2">
        <div>项目报备入口</div>
        <img src="../../src/assets/33.png" alt class="entrance_img" />
      </div>
      <div @click="records(userinfo.role)" class="entrancediv fx">
        <div>{{userinfo.role==2?'报备记录':'任务记录' }}</div>
        <img src="../../src/assets/25.png" alt class="entrance_img_tow" />
      </div>
    </div>
  </div>
</template>
<script>
import { LoginOut } from "../api/home.js";
export default {
  data() {
    return {
      userinfo: {},
      showPopover: false,
      actions: [
        { text: "修改密码", id: 1 },
        { text: "退出登录", id: 2 }
      ]
    };
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
  },
  methods: {
    //  项目报备入口
    entrance() {
      this.$router.push("/ProjectReporting");
    },
    // 报备记录
    records(role) {
      if (role == 2) {
        this.$router.push("/ReportingRecords");
      } else {
        this.$router.push("/AllocationRecords");
      }
    },
    onSelect(action) {
      if (action.id == 1) {
        this.$router.push("/ChangePassword");
      } else {
        this.$dialog
          .confirm({
            message: "是否退出当前账号"
          })
          .then(() => {
            LoginOut().then(res => {
              if (res.code == 200) {
                localStorage.removeItem("Token");
                setTimeout(() => {
                  this.$router.push("/");
                }, 500);
              }
            });
          })
          .catch(() => {});
      }
    }
  }
};
</script>
<style scoped lang="scss">
.Parent_element {
  width: 100%;
  height: 100vh;
  background-image: url("../../src/assets/16.jpg"); /* 设置图片路径 */
  background-size: cover; /* 背景图片覆盖整个元素 */
  background-repeat: no-repeat; /* 背景图片不重复 */
  background-position: center; /* 背景图片居中 */
  position: relative;
}
.entrance {
  padding: 0px 10px;
  box-sizing: border-box;
  width: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.Launch {
  font-size: 18px;
  position: absolute;
  top: 15px;
  left: 25px;
  color: #ffffff;
  display: flex;
}
.reference {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.title {
  margin-left: 10px;
}
.Launch_img {
  width: 40px;
  height: 40px;
}
.entrancediv {
  width: 400px;
  background: #ffffff;
  border-radius: 10px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  padding: 20px 15px;
  box-sizing: border-box;
  box-sizing: border-box;
}
.entrance_img {
  width: 37px;
  height: 38px;
}
.entrance_img_tow {
  width: 40px;
  height: 38px;
}
@media (max-width: 750px) {
  .entrance {
    padding: 0px 10px;
    box-sizing: border-box;
    width: 95%;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -40%);
  }
  .entrancediv {
    width: 88%;
    background: #ffffff;
    border-radius: 10px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 20px 15px;
    box-sizing: border-box;
    height: 90px;
  }
}
</style>
