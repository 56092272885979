import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: 1234
  },
  getters: {
    currentUser: state => state.user,
  },
  mutations: {
    updateUser(state,user) {
      state.user=user;
  },
  upadatset(state,user){
    state.user=user
  }
  },
  // 修改里面的state的数据
  actions: {
    asyncUpdateUser(context,user){
      context.commit('updateUser',user);
  }
  },
  modules: {}
})
